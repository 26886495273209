import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import ComponentTextblock3 from "../../components/component-textblock-3";
//import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  //const settings = {
  //  dots: true,
  //  infinite: false,
  //  speed: 500,
  //  //autoplay: true,
  //  autoplaySpeed: 4000,
  //  arrows: false,
  //  slidesToShow: 1,
  //  //centerPadding: '100px',
  //  focusOnSelect: true,
  //  //centerMode: true,
  //  variableWidth: true,
  //  responsive: [
  //    {
  //      breakpoint: 896,
  //      settings: {
  //        centerMode: false,
  //      },
  //    },
  //  ],
  //};

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `Advanced speech and communication technology for ALS`
            : `Fortschrittliche Technologie für Sprache und Kommunikation bei ALS`
        }
        description={
          intl.locale === "en"
            ? `Communicate and control your environment with invisible power.`
            : `Kommunizieren Sie mit Ihrer Umwelt und kontrollieren Sie diese mit einer unsichtbaren Macht. `
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/2R2rV27zvHm9dRLNA9MRdR/fd41c9f4feb8b5d25fd616e422600c16/E175.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-hellblau position-relative">
        <StaticImage
          src="../../images/products/communication/header-cyin.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../images/products/communication/header-cyin-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />

        <div className="container flex-row my-7">
          <div className="flex-column-6" />
          <div className="flex-column-6">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Innovative interfaces for people who have severe difficulty
  speaking or moving`
                : `Innovative Schnittstellen für Menschen mit schweren Sprach- und Bewegungseinschränkungen`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en" ? `CYIN - for Living Support` : `CYIN`}
            </p>
            <p>
              {intl.locale === "en"
                ? `Cyin makes it possible to perform arbitrary operations of various
  communication and environmental control devices, such as simple
  "yes / no" intention display, arbitrary text input using timed
  triggers, and nurse calls.`
                : `CYIN ermöglicht die beliebige Bedienung von verschiedenen Kommunikations- und Umweltsteuernden Geräten. Zum Beispiel "Ja"/"Nein" Absichtsbildschirme, Texteingabe mit zeitgesteuerten Impulsen und Schwesternruf. `}
            </p>
          </div>
        </div>
      </section>

      <section className="sect p-0 bg-bls">
        <div className="container flex-row mt-9">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `Assisting people for communication and environmental control`
                : `Menschen in der Kommunikation und Umgebungskontrolle unterstützen`}
            </h2>
            {intl.locale === "en" ? (
              <p className="mb-6">
                Innovative interfaces for people who have severe difficulty
                speaking or moving
                <br />
                Cyin for Living Support is a novel interface that utilizes
                biological information that can be controlled voluntarily
                outside of muscle activities. Even for an user with severe
                disabilities who has difficulties speaking or writing can
                communicate and operate other devices without having to speak or
                physically moving their body.
              </p>
            ) : (
              <p className="mb-6">
                Cyin for Living Support ist eine neuartige Schnittstelle, die
                biologische Informationen nutzt, die außerhalb von
                Muskelaktivitäten willentlich gesteuert werden können. Selbst
                ein Benutzer mit schweren Behinderungen, der Schwierigkeiten
                beim Sprechen oder Schreiben hat, kann kommunizieren und andere
                Geräte bedienen, ohne sprechen oder seinen Körper bewegen zu
                müssen.
              </p>
            )}

            {intl.locale === "en" ? (
              <p>
                Cyin for Living Support reads faint bio-electrical signals sent
                from the user’s brain to the muscles through the nerves when the
                user intends to move the body.
                <br />
                The device makes it possible for people who cannot move their
                own body at all due to progression of their disease or who have
                difficulty operating equipment through muscle activities (blink,
                breath, etc.) to communicate by manifestation of yes or no, or
                by writing a text, etc.) or to operate various environmental
                control equipment such as a nurse call.
                <br />
                Cyin for Living Support uses a sensor attached to the skin
                surface to detect bio-electrical signals as “motor unit
                potential”. When the detected potential exceeds a preset
                threshold, it is used as an input signal. Signal detection
                varies from person to person and is not guaranteed to be usable
                by everyone.
              </p>
            ) : (
              <p>
                Cyin for Living Support liest schwache bioelektrische Signale,
                die vom Gehirn des Benutzers über die Nerven an die Muskeln
                gesendet werden, wenn der Benutzer beabsichtigt, den Körper zu
                bewegen. Das Gerät ermöglicht es Menschen, die ihren Körper
                aufgrund des Fortschreitens ihrer Krankheit überhaupt nicht mehr
                bewegen können oder die Schwierigkeiten haben, Geräte durch
                Muskeltätigkeiten (Blinzeln, Atmen usw.) zu bedienen, durch die
                Äußerung von Ja oder Nein zu kommunizieren (oder durch das
                Schreiben eines Textes usw.) oder verschiedene
                Umweltkontrollgeräte zu kontrollieren, z.B. einen Schwesternruf.
                <br />
                <br />
                Cyin for Living Support verwendet einen auf der Hautoberfläche
                angebrachten Sensor, um bioelektrische Signale als “motorisches
                Aktionspotenzial” zu erkennen. Wenn das erkannte Potenzial einen
                voreingestellten Schwellenwert überschreitet, wird es als
                Eingangssignal verwendet. Die Signalerkennung ist von Person zu
                Person unterschiedlich und kann nicht von allen Menschen genutzt
                werden.
              </p>
            )}
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Flexibly connected to input equipments such as sensors or output
  equipments already owned by the patient such as computers`
                : `Kann flexibel mit verschiedenen Eingabegeräten, wie Sensoren, oder Ausgabegeräten, wie Computern,  die der Patient bereits besitzt verbunden werden`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Palm-size and light. Cyin can be carried and used outside. The
  battery could be charged wirelessly`
                : `Handflächengroß und leicht. Cyin kann getragen und auch draußen benutzt werden. Die Batterie kann kabellos geladen werden.`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Faint bio-electric signals are only requirements for the patients`
                : `Vom Patienten sind nur kleinste bioelektrische Signale von Nöten `}
            </p>
          </div>
        </div>
      </section>

      <section className="sect">
        <div className="container flex-row">
          <div className="flex-column-7">
            <h3 className="txt txt-rot">
              {intl.locale === "en"
                ? `Flexible connection to external devices`
                : `Flexible Anbindung an externe Geräte`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `As a “communication device for severely disabled persons” the main
  unit of Cyin for Living Support can be used as a device to
  manifest yes or no using biological information. The device can
  also be connected to various other devices such as PC, tablets and
  other communication devices. For example, when combined with a
  voice-to-speech output device, the user can communicate with their
  eyes closed.`
                : `Als "Kommunikationsgerät für schwerbehinderte Menschen" kann die Haupteinheit von Cyin for Living Support als Gerät verwendet werden, um mithilfe biologischer Informationen Ja oder Nein zu sagen. Das Gerät kann auch an verschiedene andere Geräte wie PC, Tablets und andere Kommunikationsgeräte angeschlossen werden. In Kombination mit einem Sprachausgabegerät kann der Benutzer beispielsweise mit geschlossenen Augen kommunizieren.`}
            </p>
            <h3 className="txt txt-rot">
              {intl.locale === "en"
                ? `Quick and easy setting with touch panel`
                : `Schnelle und einfache Einstellung mit Touchpanel`}
            </h3>
            {intl.locale === "en" ? (
              <p>
                Cyin for Living Support aims to reduce the burden of those who
                support the user to put-on the device.
                <br />
                <br />
                After attaching the sensor and connecting the equipment to be
                combined, set the level and threshold with the touch panel while
                checking the sensor signal, and press the output button. This
                would complete the preparation.
                <br />
                <br />
                The device is easy to operate even for first-time users.
              </p>
            ) : (
              <p>
                Cyin for Living Support zielt darauf ab, die Belastung
                derjenigen zu verringern, die den Benutzer beim Anlegen des
                Geräts unterstützen.
                <br />
                <br />
                Nach dem Anbringen des Sensors und dem Anschließen der zu
                kombinierenden Geräte stellen Sie den Pegel und den
                Schwellenwert mit dem Touchpanel ein, während Sie das
                Sensorsignal überprüfen, und drücken Sie die Ausgabetaste. Damit
                wäre die Vorbereitung abgeschlossen. Das Gerät ist auch für
                Erstanwender leicht zu bedienen.
              </p>
            )}

            <h3 className="txt txt-rot">
              {intl.locale === "en"
                ? `8-channel I/O to expand the range of uses`
                : `8-Kanal-E/A zur Erweiterung der Einsatzmöglichkeiten`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `The bio-electrical signals required to control Cyin for Living
  Support can be detected from various parts of the body, and can be
  adapted to the physical condition of each user. Cyin for Living
  Support has eight input and output channels, allowing multiple
  signals to be used simultaneously by attaching sensors to multiple
  body parts that read the signals. This makes it possible to freely
  operate multiple devices and or to freely conduct complex
  operations of devices. Signal from multiple parts of the body can
  also be combined together to create stronger input signals`
                : `Die für die Steuerung von Cyin for Living Support erforderlichen bioelektrischen Signale können von verschiedenen Körperteilen erfasst und an die körperliche Verfassung des jeweiligen Benutzers angepasst werden. Cyin for Living Support verfügt über acht Eingangs- und Ausgangskanäle, sodass mehrere Signale gleichzeitig verwendet werden können. Sensoren können an mehreren Körperteilen angebracht werden, die die Signale lesen. Dadurch ist es möglich, mehrere Geräte frei zu bedienen oder komplexe Operationen von Geräten frei durchzuführen. Die Signale von mehreren Körperteilen können auch kombiniert werden, um stärkere Eingangssignale zu erzeugen.`}
            </p>
          </div>
          <div className="flex-column-5">
            <StaticImage
              src="../../images/products/communication/241_image.png"
              className=""
            />
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                                      {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}

                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Let's find how we can change the way you communicate`
                : `Finden Sie heraus wie CYIN Ihre Wege der Kommunikation ändert.`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Start new journey with Cyberdyne`
                : `Starten Sie Ihre Reise mit Cyberdyine`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
